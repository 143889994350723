import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import Modal from 'react-bootstrap/lib/Modal';
import TabsAccount from 'User/Account/TabsAccount';
import LazyImage from 'helpers/LazyImage';
import {isNative} from '../ferries/helpers/common';
import {userAccountTranslations} from 'User/helpers/translations';
import loginIllustration from 'User/helpers/images/login-illustration.svg';

const {loginSlogan, login} = userAccountTranslations;

const AccountModal = ({isMobile, email, children}) => {
  const [showModal, setShowModal] = useState(false);
  const [tabIndexSelected, setTabIndexSelected] = useState(0);

  const onChangeTab = (index) => {
    setTabIndexSelected(index);
  };

  const toggleModal = (tabIndex) => {
    setShowModal((showModal) => !showModal);
    if ([0, 1].includes(tabIndex)) {
      setTabIndexSelected(tabIndex);
    }
  };

  const onClickAccount = (e) => {
    const el = e.target.closest('a');
    if (el && e.currentTarget.contains(el)) {
      e.preventDefault();
      setTabIndexSelected(parseInt(el.getAttribute('tab')));
      toggleModal();
    }
  };

  if (isNative()) {
    return <></>;
  }

  return (
    <>
      <div>
        {children(toggleModal, onClickAccount)}
      </div>
      <Modal
        dialogClassName="user-login-modal"
        bsSize="lg"
        show={showModal}
        onHide={toggleModal}
      >
        <div className="position-relative">
          <div className="position-absolute" style={{top: 26, right: 20, zIndex: 10}}>
            <button type="button" className="close" onClick={toggleModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="grid no-gutter">
            {!isMobile &&
            <div
              className="grid-item-auto bg-primary position-relative px-5 "
              style={{borderRadius: '24px 0 0 24px', width: '340px'}}
            >
              <div
                className="text-center text-white mt-8 font-bold"
                style={{fontSize: '56px'}}
              >
                {loginSlogan}
              </div>
              <LazyImage
                wrapperClass='position-absolute w-1-1'
                wrapperStyle={{bottom: 0, left: 0}}
                src={loginIllustration}
                width="100%"
                role="login-illustration"
                alt={login}
                title={login}
              />
            </div>}
            <div className="px-4 py-3 grid-item">
              <TabsAccount
                isModal
                tabIndexSelected={tabIndexSelected}
                email={email}
                onChangeTab={onChangeTab}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

AccountModal.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  email: PropTypes.string,
  children: PropTypes.func.isRequired
};

export default observer(AccountModal);
