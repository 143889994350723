import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import classnames from 'classnames';
import useAsync from 'User/helpers/hooks/useAsync';
import SocialBtn from 'User/Account/SocialBtn';
import {loginUser} from 'providers/user/auth';
import {setUserLoggedinMessage} from 'utils/session';
import {boardingRoute} from 'User/helpers/routes';
import {isProduction} from 'helpers/stableValues';
import googleLogo from 'assets/images/social/google.png';
import appleLogo from 'assets/images/social/apple.png';
import facebookLogo from 'assets/images/social/facebook.png';
import {userAccountTranslations} from 'User/helpers/translations';

const {loginWithGoogle, loginWithApple, loginWithFacebook} = userAccountTranslations;

const SocialAccount = ({isAccountPage, isModal}) => {
  const {isSuccess, data, execute} = useAsync();

  useEffect(() => {
    setTimeout(() => {
      window?.google?.accounts?.id?.initialize({
        client_id: '1016037285519-ko39v4c8q18elpi9hovf3e1i0bdcgfcj.apps.googleusercontent.com',
        ux_mode: 'popup',
        context: 'signin',
        auto_prompt: false,
        callback: ({credential}) => {
          execute(loginUser({
            accessToken: credential,
            email: null,
            password: null,
            provider: 'google',
            providerUserID: null
          }));
        }
      });
    }, 1000);
  }, [execute]);

  useEffect(() => {
    setTimeout(() => {
      window.FB?.init({
        appId : '451880447871547',
        cookie: true,
        xfbml : true,
        version : 'v21.0'
      });
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.AppleID?.auth?.init({
        clientId : 'com.ferryscanner.www',
        scope : 'name email',
        redirectURI : `${location.origin}/en/ferry`,
        state : '',
        nonce : 'NONCE',
        usePopup : true
      });
    }, 500);
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      const {result: {uuid, session_token}} = data;
      localStorage.setItem('user', JSON.stringify({uuid, session_token}));
      Cookies.set(
        'TP24ID',
        session_token,
        {path: '/', secure: isProduction, sameSite: 'lax'}
      );

      setTimeout(() => {
        if (isAccountPage) {
          window.location = boardingRoute;
        }

        if (isModal) {
          setUserLoggedinMessage();
          location.reload();
        }
      }, 500);
    }
  }, [isSuccess, data, isAccountPage, isModal]);

  const onloginApple = async () => {
    try {
      const {authorization, user} = await window.AppleID.auth.signIn();
      execute(loginUser({
        accessToken: authorization.id_token,
        provider: 'apple',
        email: null,
        password: null,
        providerUserID: null,
        firstName: user?.name?.firstName ?? null,
        lastName: user?.name?.lastName ?? null
      }));
    } catch {
      return;
    }
  };

  const onloginFacebook = () => {
    window.FB.login((response) => {
      if (response.authResponse) {
        const {accessToken, userID} = response.authResponse;
        execute(loginUser({
          accessToken: accessToken,
          email: null,
          password: null,
          provider: 'facebook',
          providerUserID: userID
        }));
        // FB.api('/me', {fields: 'name, email'}, async ({id, email}) => {});
      }
    }, {scope: 'email'});
  };


  const createFakeGoogleWrapper = () => {
    const googleLoginWrapper = document.createElement('div');
    googleLoginWrapper.style.display = 'none';
    googleLoginWrapper.classList.add('custom-google-button');
    document.body.appendChild(googleLoginWrapper);
    window.google.accounts.id.renderButton(googleLoginWrapper, {
      type: 'icon',
      width: '200'
    });
    const googleLoginWrapperButton = googleLoginWrapper.querySelector('div[role=button]');

    return {
      click: () => {
        googleLoginWrapperButton.click();
      }
    };
  };

  const onloginGoogle = () => {
    const googleButtonWrapper = createFakeGoogleWrapper();
    googleButtonWrapper.click();
  };

  const socialBtns = [
    {
      logo: googleLogo,
      label: loginWithGoogle,
      onClick: onloginGoogle
    },
    {
      logo: appleLogo,
      label: loginWithApple,
      onClick: onloginApple
    },
    {
      logo: facebookLogo,
      label: loginWithFacebook,
      onClick: onloginFacebook
    }
  ];

  const socialAccountClass = classnames('d-flex flex-column align-items-center',{
    'mt-6': !isModal,
    'mt-3': isModal
  });

  return (
    <div className={socialAccountClass}>
      {socialBtns.map(({logo, label, onClick}, index) => (
        <SocialBtn
          key={index}
          isFirst={index === 0}
          logo={logo}
          label={label}
          onClick={onClick}
        />
      ))}
    </div>
  );
};

SocialAccount.propTypes = {
  isAccountPage: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired
};

export default SocialAccount;
