import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda/src/index';
import Modal from 'react-bootstrap/lib/Modal';
import PreferredLocale from 'components/authentication/PreferredLocale';
import PreferredCurrency from 'components/authentication/PreferredCurrency';
import Button from 'helpers/Button';
import {createLanguageUrl} from 'components/authentication/helper';
import {fetchConfiguration} from 'providers/configuration';

const createLanguages = (locales) => R.zipWith((x, y) => {
  return {
    locale: x,
    label: R.prop('language', y),
    language: R.prop('lang', y),
    url: R.prop('url', y),
    order: R.prop('order', y)
  };
}, R.keys(locales), R.values(locales));

const RegionalSettingModal = ({
  locales,
  preferredLocale,
  brandLocale,
  isPreferredCurrencyEnabled,
  currencies,
  preferredCurrency,
  toggleModal,
  translations
}) => {
  const [locale, setLocale] = useState(preferredLocale);
  const [currency, setCurrency] = useState(preferredCurrency);

  const languages = createLanguages(locales);
  languages.sort((a, b) => a.order - b.order);

  const {language: preferredLanguage, lang: preferredLang} = locales[locale];
  const {regionalSettings, save} = translations;

  const onChangeLocale = (locale) => {
    setLocale(locale);
  };

  const onChangeCurrency = (currency) => {
    setCurrency(currency);
  };

  const onSave = async () => {
    const {body: {results: {data: {currencyDetails}}}} = await fetchConfiguration(currency);
    sessionStorage.setItem('currency-storage', JSON.stringify({...currencyDetails}));

    const selLanguage = languages.find((language) => language.locale === locale);
    const href = createLanguageUrl({selLanguage, preferredLang: locales[preferredLocale].lang});
    if (location.href === href) {
      location.reload();
    } else {
      location.href = href;
    }
  };

  return (
    <Modal show onHide={toggleModal} bsSize="small">
      <div className="bg-snow p-3 border-radius-lg">
        <div className="d-flex align-items-center justify-content-between two-border-bottom-dotted mb-5 pb-3">
          <div className="h4 d-flex align-items-center font-bold text-primary">
            <i className="icon icon-world mr-2"/>{regionalSettings}
          </div>
          <button type="button" className="close text-primary" onClick={toggleModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form>
          <PreferredLocale
            languages={languages}
            locales={locales}
            brandLocale={brandLocale}
            locale={locale}
            preferredLang={preferredLang}
            preferredLanguage={preferredLanguage}
            onChangeLocale={onChangeLocale}
            translations={translations}
          />
          {
            isPreferredCurrencyEnabled && currencies.length > 0 && <PreferredCurrency
              currencies={currencies}
              currency={currency}
              preferredCurrency={preferredCurrency}
              onChangeCurrency={onChangeCurrency}
              translations={translations}
            />
          }
        </form>
        <Button
          aria-label={save}
          status="primary"
          className="w-1-1"
          onClick={onSave}
        >
          {save}
        </Button>
      </div>
    </Modal>
  );
};

RegionalSettingModal.propTypes = {
  locales: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  brandLocale: PropTypes.string.isRequired,
  preferredLocale: PropTypes.string.isRequired,
  isPreferredCurrencyEnabled: PropTypes.bool.isRequired,
  preferredCurrency: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    regionalSettings: PropTypes.string.isRequired,
    save: PropTypes.string.isRequired
  })
};

export default RegionalSettingModal;
