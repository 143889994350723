import React from 'react';
import PropTypes from 'prop-types';

const LinkAccount = ({text, label, onClick}) => (
  <div className="text-center mt-3">
    <span className="mr-1">{text}</span>
    <a onClick={onClick}>{label}</a>
  </div>
);

LinkAccount.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default LinkAccount;
