import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SocialBtn = ({isFirst, logo, label, onClick}) => {
  const btnClasses = classnames('cursor-pointer grid small-gutter bg-white border border-light px-2', {
    'mt-3': !isFirst
  });

  return (
    <div
      className={btnClasses}
      style={{width: '100%', height: '56px', borderRadius: '16px'}}
      onClick={onClick}
    >
      <div className="grid-item-auto d-flex align-items-center">
        <img
          src={logo}
          alt={label}
          title={label}
          width="32px"
          height="32px"
        />
      </div>
      <div className="grid-item d-flex align-items-center">{label}</div>
      <div className="grid-item-auto d-flex align-items-center">
        <span className="icon icon-right-arrow" />
      </div>
    </div>
  );
};

SocialBtn.defaultProps = {
  isFirst: false
};

SocialBtn.propTypes = {
  logo: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isFirst: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

export default SocialBtn;
