import qs from 'qs';

const STORAGE_CUID = 'cuid';

const generateUUID = () => {
  const d = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16)%16 | 0;
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
};

const setCustomerUserId = () => {
  if (window.AF) {
    const {cuid: cuidParam} = qs.parse(location.search.slice(1));
    const cuidStorage = localStorage.getItem(STORAGE_CUID);

    const cuid = cuidParam ?? cuidStorage ?? generateUUID();

    if (cuid) {
      localStorage.setItem(STORAGE_CUID, `${cuid}`);
      window.AF('pba', 'setCustomerUserId', `${cuid}`); // eslint-disable-line new-cap
    }
  }
};

export {setCustomerUserId};
